
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// Components
import ProductFilter from './filter-container.component.vue';

// Models
import RangeFilterModel from '../models/range-filter.model';

@Component({
  components: {
    ProductFilter
  }
})
export default class RangeFilter extends Vue {

  @Prop({
    type: RangeFilterModel,
    required: true
  })
  filter!: RangeFilterModel;

  editingMinValue = false;
  editingMaxValue = false;

  sliderWidth = 0;
  internalValue: number[] = [];

  get currentValue() {
    return this.$store.state.productSearch.filtering.values[this.filter.id]
  }

  get minPosition() {
    const total = this.filter.maxValue - this.filter.minValue;
    const adjustedValue = this.internalValue[0] - this.filter.minValue;

    const percentage = adjustedValue/total;

    return this.sliderWidth * percentage;
  }

  get maxPosition() {
    const total = this.filter.maxValue - this.filter.minValue;
    const adjustedValue = this.internalValue[1] - this.filter.minValue;

    const percentage = adjustedValue/total;

    return this.sliderWidth * percentage;
  }

  protected mounted() {
    this.updateSliderWidth();
    this.updateTempValue();

    window.addEventListener('resize', this.updateSliderWidth);
  }

  protected destroyed() {
    window.removeEventListener('resize', this.updateSliderWidth);
  }

  updateSliderWidth() {
    this.sliderWidth = (this.$refs.slider as HTMLDivElement).offsetWidth;
  }

  @Watch('currentValue')
  updateTempValue() {
    this.internalValue = this.currentValue;
  }

  startEditingMinValue() {
    this.editingMinValue = true;

    setTimeout(() => {
      (this.$refs.minInput as HTMLInputElement)?.focus();
    });
  }

  startEditingMaxValue() {
    this.editingMaxValue = true;

    setTimeout(() => {
      (this.$refs.maxInput as HTMLInputElement)?.focus();
    });
  }

  handleMinInputChange(event: InputEvent) {
    const value = parseInt((event.target as HTMLInputElement).value);

    this.editingMinValue = false;

    if (value !== this.internalValue[0]) {
      this.handleMinValueChange(value);
      this.submitTempValue();
    }
  }

  handleMaxInputChange(event: InputEvent) {
    const value = parseInt((event.target as HTMLInputElement).value);

    this.editingMaxValue = false;

    if (value !== this.internalValue[1]) {
      this.handleMaxValueChange(value);
      this.submitTempValue();
    }
  }

  handleMinValueChange(value) {
    // Check if new value is not out of bounds
    if (value < this.filter.minValue) {
      value = this.filter.minValue;
    }
    if (value > this.internalValue[1]) {
      value = this.internalValue[1];
    }

    // Round to the nearest integer
    value = Math.round(value);

    const newIntervalValue = this.internalValue.slice();
    newIntervalValue[0] = value;
    this.internalValue = newIntervalValue
  }

  handleMaxValueChange(value) {
    // Check if new value is not out of bounds
    if (value < this.internalValue[0]) {
      value = this.internalValue[0];
    }
    if (value > this.filter.maxValue) {
      value = this.filter.maxValue;
    }

    // Round to the nearest integer
    value = Math.round(value);

    const newIntervalValue = this.internalValue.slice();
    newIntervalValue[1] = value;
    this.internalValue = newIntervalValue
  }

  startDragging(event: MouseEvent | TouchEvent, isMax: boolean) {
    const initialDragPosition = (event as TouchEvent).touches ? (event as TouchEvent).touches[0].clientX : (event as MouseEvent).x;

    const mouseMoveHandler = event => {
      const position = (event as TouchEvent).touches ? (event as TouchEvent).touches[0].clientX : (event as MouseEvent).x;
      const delta = position - initialDragPosition;
      const deltaPercentage = delta / this.sliderWidth;

      const total = this.filter.maxValue - this.filter.minValue;
      const valueDelta = deltaPercentage * total;

      if (isMax) {
        this.handleMaxValueChange(this.currentValue[1] + valueDelta);
      }
      else {
        this.handleMinValueChange(this.currentValue[0] + valueDelta);
      }
    };
    const stopDraggingHandler = () => {
      this.submitTempValue();

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', stopDraggingHandler);
      document.removeEventListener('touchmove', mouseMoveHandler);
      document.removeEventListener('touchend', stopDraggingHandler);
    }

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', stopDraggingHandler);
    document.addEventListener('touchmove', mouseMoveHandler);
    document.addEventListener('touchend', stopDraggingHandler);
  }

  submitTempValue() {
    this.$store.dispatch('productSearch/setFilterValue', {
      id: this.filter.id,
      value: this.internalValue
    });
  }

}
