
import { Component, Prop, Vue } from 'vue-property-decorator';

// Components
import ProductFilter from './filter-container.component.vue';

// Models
import TextFilterModel from "@/models/text-filter.model";

@Component({
  components: {
    ProductFilter
  }
})
export default class TextFilter extends Vue {

  @Prop({
    type: TextFilterModel,
    required: true
  })
  filter!: TextFilterModel;

  get currentValue() {
    return this.$store.state.productSearch.filtering.values[this.filter.id];
  }

  set currentValue(value) {
    this.$store.dispatch('productSearch/setFilterValue', {
      id: this.filter.id,
      value
    });
  }

}
