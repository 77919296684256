import FilterModel, {ProductFilterType} from "@/models/filter.model";

export interface TextFilterModelData {
  id: string;
  name: string;
}

export default class TextFilterModel extends FilterModel {

  constructor (data: TextFilterModelData) {
    super(data);

    this.type = ProductFilterType.Text;
  }

  getFilterQueryFromValue(value: string): string {
    if (value === '') {
      return '';
    }

    return value
      .split(' ')
      .map(subValue => `${this.id}:${subValue}`)
      .join(' AND ');
  }
}
