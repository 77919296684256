
import { Component, Prop, Vue } from 'vue-property-decorator';

// Models
import FilterModel from '@/models/filter.model';

@Component
export default class FilterContainer extends Vue {

  @Prop({
    type: FilterModel,
    required: true
  })
  filter!: FilterModel;

  open = true;

  toggleContainer (open?: boolean): void {
    this.open = typeof open === 'boolean' ? open : !this.open;
  }

}
