
import { Component, Vue } from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import LoadingSpinner from "@/components/loading-spinner.component.vue";
import PageHeader from '@/components/page-header.component.vue';
import ProductFilter from "@/components/product-filter.component.vue";
import ProductTile from '@/components/product-tile.component.vue';
import Sort from "@/components/sort.component.vue";

// Models
import ProductModel from '@/models/product.model';
import FilterModel from "@/models/filter.model";

@Component({
  components: {
    LoadingSpinner,
    PageHeader,
    ProductFilter,
    ProductTile,
    Sort
  }
})
export default class ShopView extends Vue {

  showFilters = false;

  get filters(): FilterModel[] {
    const filters = this.$store.state.productSearch.filtering.filters;

    // Filter out the title filter since that is being handled somewhere else
    return filters.filter((filter: FilterModel) => filter.id !== 'title');
  }

  get hasNextPage(): boolean {
    return this.$store.state.productSearch.pagination.hasNextPage;
  }

  get loading(): boolean {
    return this.$store.state.productSearch.loading;
  }

  get products(): ProductModel[] {
    return this.$store.state.productSearch.products;
  }

  protected created() {
    // Load products if none are available
    if (this.products.length === 0) {
      this.loadProducts();
    }

    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.Shop
    });
  }

  loadProducts(): void {
    this.$store.dispatch('productSearch/searchProducts');
  }

  toggleFilters(show): void {
    this.showFilters = typeof show === 'boolean' ? show : !this.showFilters;
  }
}
