
import { Component, Prop, Vue } from 'vue-property-decorator';

// Components
import ProductFilter from './filter-container.component.vue';

// Models
import MultiselectFilterModel from '../models/multiselect-filter.model';

@Component({
  components: {
    ProductFilter
  }
})
export default class MultiSelectFilter extends Vue {

  @Prop({
    type: MultiselectFilterModel,
    required: true
  })
  filter!: MultiselectFilterModel;

  get currentValue() {
    return this.$store.state.productSearch.filtering.values[this.filter.id];
  }

  toggleOption (value: number | string): void {
    const valueClone = this.currentValue.slice();
    const valueIndex = this.currentValue.indexOf(value);

    if (valueIndex !== -1) {
      valueClone.splice(valueIndex, 1);
    } else {
      valueClone.push(value);
    }

    this.$store.dispatch('productSearch/setFilterValue', {
      id: this.filter.id,
      value: valueClone
    });
  }

}
