
import { Component, Prop, Vue } from 'vue-property-decorator';

// Components
import MultiSelectFilter from './multi-select-filter.component.vue';
import RangeFilter from './range-filter.component.vue';
import TextFilter from "@/components/text-filter.component.vue";

// Models
import FilterModel, { ProductFilterType } from '../models/filter.model';

@Component({
  components: {
    RangeFilter,
    MultiSelectFilter,
    TextFilter
  }
})
export default class ProductFilter extends Vue {

  @Prop({
    type: FilterModel,
    required: true
  })
  filter!: FilterModel;

  ProductFilterType = ProductFilterType;

}
