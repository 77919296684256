
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Sort extends Vue {
  sortOptions = [
    {
      value: 'RELEVANCE',
      label: this.$t('Components.Sort.OrderByRelevance')
    },
    {
      value: 'PRICE',
      label: this.$t('Components.Sort.OrderByPrice')
    },
    {
      value: 'TITLE',
      label: this.$t('Components.Sort.OrderByName')
    }
  ]

  get currentSort(): string {
    return this.$store.state.productSearch.sort.current;
  }

  get currentDirection(): string {
    return this.$store.state.productSearch.sort.direction;
  }

  setCurrentSort(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.$store.dispatch('productSearch/setCurrentSort', {
      sort: value
    });
  }

  toggleDirection(): void {
    this.$store.dispatch('productSearch/setCurrentSort', {
      direction: this.currentDirection === 'asc' ? 'desc' : 'asc'
    });
  }
}
